<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- begin::Loading -->
        <template v-if="isMounted === false || isLoading">
          <div class="loading-container loading-overlap">
            <div class="loading-backdrop rounded" />
            <div class="loading-block">
              <div class="blockui ml-auto mr-auto w-100">
                <span class="float-left">Gathering data...</span>
                <span class="spinner spinner-primary ml-auto" />
              </div>
            </div>
          </div>
        </template>
        <!-- end::Loading -->

        <!-- begin::Error -->
        <template v-if="isErrorActive">
          <div class="loading-container loading-overlap">
            <div class="loading-backdrop" />
            <div class="loading-block w-75">
              <error-translation-alert
                ref="errorTranslationAlert"
                :error="activeError"
                class="shadow"
              />
            </div>
          </div>
        </template>
        <!-- end::Error -->

        <!-- begin::Table -->
        <table class="table">
          <thead>
            <tr>
              <th>Log</th>
              <th class="text-center">Sunbed</th>
              <th class="text-center">Module</th>
              <th class="text-center">Server</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link :to="{ name: '/sunbeds/view/:guid/settings/sunbed' }">Parameter log</router-link>
              </td>
              <td class="text-center">{{ getLogId('controller', 'parameter') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('device', 'parameter') === false }]">{{ getLogId('device', 'parameter') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('server', 'parameter') === false }]">{{ getLogId('server', 'parameter') }}</td>
            </tr>

            <tr>
              <td>
                <router-link :to="{ name: '/sunbeds/view/:guid/health/modules' }">Boot log</router-link>
              </td>
              <td class="text-center">{{ getLogId('controller', 'boot') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('device', 'boot') === false }]">{{ getLogId('device', 'boot') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('server', 'boot') === false }]">{{ getLogId('server', 'boot') }}</td>
            </tr>

            <tr>
              <td>Session log</td>
              <td class="text-center">{{ getLogId('controller', 'session') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('device', 'session') === false }]">{{ getLogId('device', 'session') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('server', 'session') === false }]">{{ getLogId('server', 'session') }}</td>
            </tr>

            <tr>
              <td>
                <router-link :to="{ name: '/sunbeds/view/:guid/health/errors' }">Error log</router-link>
              </td>
              <td class="text-center">{{ getLogId('controller', 'error') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('device', 'error') === false }]">{{ getLogId('device', 'error') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('server', 'error') === false }]">{{ getLogId('server', 'error') }}</td>
            </tr>

            <tr>
              <td>Event log</td>
              <td class="text-center">{{ getLogId('controller', 'event') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('device', 'event') === false }]">{{ getLogId('device', 'event') }}</td>
              <td :class="['text-center', { 'text-warning': matchesWithController('server', 'event') === false }]">{{ getLogId('server', 'event') }}</td>
            </tr>
          </tbody>
        </table>
        <!-- end::Table -->
      </div>
    </div>
  </div>
</template>

<script>
import { errorComponentMixin } from '@vedicium/core-vue';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin, equipmentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      logs: {},
    };
  },

  async mounted () {
    this.$set(this, 'isLoading', true);
    try {
      this.$set(this, 'logs', await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs/summary`));
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
    } finally {
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    getLogId (resource = null, type = null) {
      if (!resource || !type) {
        return null;
      }

      if (!this.logs || !this.logs[resource] || Array.isArray(this.logs[resource]) === false) {
        return null;
      }

      const logTypeOfResource = (this.logs[resource] || []).find((log) => log.type === type);
      return (logTypeOfResource ? logTypeOfResource.log_id : 0);
    },

    matchesWithController (resource = null, type = null) {
      return this.getLogId(resource, type) === this.getLogId('controller', type);
    },
  },
};
</script>
